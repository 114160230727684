<template>
  <div id="landing0824">
    <section
      class="img-container img-container-darken"
      style="background-image: url('/imgs/landing_0824/01.jpg')"
    >
      <div class="container pt-5">
        <nav>
          <router-link class="nav-container" to="/">
            <img
              src="/svgs/logo_4.svg"
              alt="Logo NLC"
              class="logo_2 logo-nlc"
            />
          </router-link>
        </nav>
      </div>

      <div class="container py-7 text-white">
        <div class="row align-items-center gap-y-5">
          <div class="col-md-6 col-lg-7">
            <h1 class="title h2">ACCESS Program</h1>
            <h5 class="mb-5 subtitle">
              Unlock Your Future with NL College & University of Salamanca
            </h5>

            <ul>
              <li class="d-flex mb-4">
                <span
                  class="h3 lh-1 material-symbols-outlined arrow_right_alt text-secondary"
                >
                  arrow_right_alt
                </span>
                <h6>
                  The ACCESS Program is an exclusive opportunity to immerse
                  yourself in Spanish language and culture while securing a
                  pathway to higher education at one of Europe’s oldest and most
                  prestigious universities.
                </h6>
              </li>
              <li class="d-flex mb-4">
                <span
                  class="h3 lh-1 material-symbols-outlined arrow_right_alt text-secondary"
                >
                  arrow_right_alt
                </span>
                <h6>
                  This program is suitable for a Category D visa, allowing you
                  to work in Spain.
                </h6>
              </li>
              <li class="d-flex mb-4">
                <span
                  class="h3 lh-1 material-symbols-outlined arrow_right_alt text-secondary"
                >
                  arrow_right_alt
                </span>
                <h6>
                  22 degree programs with guaranteed access to University of
                  Salamanca.
                </h6>
              </li>
            </ul>
          </div>

          <form
            id="form"
            @submit.prevent="sendForm"
            class="col-md-6 col-lg-4 offset-lg-1"
          >
            <div class="text-center">
              <h4 class="form-title">Get ACCESS now</h4>
            </div>

            <input
              type="text"
              placeholder="Full name *"
              v-model="form.name"
              required
            />
            <input
              type="text"
              placeholder="E-mail *"
              v-model="form.email"
              required
            />
            <input
              type="text"
              placeholder="Telephone number / Whatsapp*"
              v-model="form.phone"
              required
            />
            <Select
              v-model="form.nationality"
              label="Please select your nationality*"
              :options="nationalities"
              name="nationality"
            />
            <Select
              v-model="form.course"
              label="Preferred University Course"
              :options="courses"
              name="course"
            />
            <div class="mb-3">
              <p class="mb-2">I want to learn Spanish in:</p>

              <div class="d-flex gap-4">
                <Radio
                  label="Barcelona"
                  inputValue="Barcelona"
                  name="campus"
                  v-model="form.campus"
                  :class="`checkbox-secondary`"
                />
                <Radio
                  label="Madrid"
                  inputValue="Madrid"
                  name="campus"
                  v-model="form.campus"
                  :class="`checkbox-secondary`"
                />
                <Radio
                  label="I don't know"
                  inputValue="I don't know"
                  name="campus"
                  v-model="form.campus"
                  :class="`checkbox-secondary`"
                />
              </div>
            </div>

            <textarea
              placeholder="Aks us anything"
              rows="4"
              v-model="form.message"
              name="message"
            />

            <Checkbox
              label="I have read and accept the NL College Privacy Policy, Terms & Conditions, and Cancellation Policy."
              v-model="form.terms"
              name="terms-accepted"
              :class="`checkbox-secondary`"
            />

            <button
              class="btn btn-block btn-secondary form-button"
              type="submit"
            >
              I want to study in Spain
            </button>
          </form>
        </div>
      </div>
    </section>

    <section
      class="img-container img-container-darken"
      style="background-image: url('/imgs/landing_0824/02.jpg')"
    >
      <div class="container py-7">
        <div class="row gap-y-4">
          <div class="col-12 mb-4 text-center text-white">
            <h3>
              Why choose <br />
              the ACCESS Program?
            </h3>
          </div>
          <div class="col-lg-4" v-for="(card, ix) of whyCards" :key="ix">
            <div
              class="card card-outline-white h-100 d-flex flex-column justify-content-between"
            >
              <div class="card-header minh-1">
                <h4>{{ card.title }}</h4>
              </div>
              <div class="card-body">
                <p>
                  {{ card.subtitle }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4 text-center text-white">
            <a href="#form" class="btn btn-secondary px-5 d-inline-block">
              Start your journey now!
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7">
      <div class="container">
        <div class="row gap-y-5 mb-6">
          <div class="col-12 text-center">
            <h3>Program <span class="text-secondary">details</span></h3>
          </div>
          <div class="col-lg-6">
            <div
              class="img-container img-container-rounded minh-3 mb-4"
              style="background-image: url('/imgs/landing_0824/03.jpg')"
            />
            <h4 class="fw-600 mb-4">1-year course, consisting of:</h4>
            <ul class="disc-style disc-secondary">
              <li>
                4-Month Spanish Course at NL College. Includes registration fee
                + books.
              </li>
              <li>
                7-Month USAL Course at University of Salamanca. Includes study
                materials, insurance, and SIELE S1 exam
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <div
              class="img-container img-container-rounded minh-3 mb-4"
              style="background-image: url('/imgs/landing_0824/04.jpg')"
            />
            <h4 class="fw-600 mb-4">This program is eligible:</h4>
            <ul class="disc-style disc-secondary">
              <li>
                For students who have completed high school or a University
                degree.
              </li>
              <li>
                People who want a 1-year Visa category D, with the possibility
                to work 30 hours part-time while studying.
              </li>
            </ul>
          </div>
          <div class="col-12 mt-4 text-center">
            <a href="#form" class="btn btn-secondary px-5 d-inline-block">
              Know more about your Program
            </a>
          </div>
        </div>

        <div class="row gap-y-5">
          <div class="col-12 text-center">
            <h3>
              <span class="text-secondary">Meet</span> NL College <br />
              & University of Salamanca
            </h3>
          </div>
          <div class="col-lg-6">
            <div
              class="img-container img-container-rounded minh-3 mb-4"
              style="background-image: url('/imgs/landing_0824/06.png')"
            />
            <h4 class="fw-600 mb-4">NL College</h4>
            <ul class="disc-style disc-secondary">
              <li>Two campuses in Spain: Madrid & Barcelona</li>
              <li>
                Focused on immersive Spanish learning with cultural activities
              </li>
              <li>Native Teachers</li>
              <li>+30 Nationalities</li>
              <li>Multilingual Student Support</li>
            </ul>
          </div>
          <div class="col-lg-6">
            <div
              class="img-container img-container-rounded minh-3 mb-4"
              style="background-image: url('/imgs/landing_0824/07.png')"
            />
            <h4 class="fw-600 mb-4">University of Salamanca</h4>
            <ul class="disc-style disc-secondary">
              <li>Founded in 1218, one of Europe's oldest universities.</li>
              <li>30,000 students across nine campuses.</li>
              <li>
                Known for academic excellence and a rich cultural heritage.
              </li>
            </ul>
          </div>
          <div class="col-12 mt-4 text-center">
            <a href="#form" class="btn btn-secondary px-5 d-inline-block">
              Know more about us
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      class="img-container img-container-darken"
      style="background-image: url('/imgs/landing_0824/05.png')"
    >
      <div class="container text-center text-white py-7">
        <div class="row gap-y-6 justify-content-center">
          <div class="col-md-9">
            <h3>Once in a lifetime opportunity</h3>
          </div>

          <div class="col-md-9">
            <h4 class="fw-600">
              This program combines academic advancement and practical work in
              culturally rich Spain. By living, studying, and working there,
              you'll gain a high-quality education and deep cultural
              appreciation. This holistic approach prepares you for a successful
              future, in Spain or beyond.
            </h4>
          </div>

          <div class="col-md-5">
            <div
              class="card card-outline-white h-100 d-flex flex-column justify-content-between"
            >
              <div class="card-header minh-1">
                <h3 class="fw-600">1-year course ACCESS Program</h3>
              </div>
              <div class="card-body">
                <h2>8,000 €</h2>
              </div>
            </div>
          </div>

          <div class="col-12">
            <a href="#form" class="btn btn-secondary px-5 d-inline-block">
              Let’s start a conversation
            </a>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-md-4 d-flex justify-content-center justify-content-md-start mb-4 mb-md-0"
          >
            <img src="/svgs/logo_2.svg" alt="Logo NLC" class="logo-nlc" />
          </div>
          <div class="col-md-4 d-flex flex-column gap-2 align-items-center">
            <a href="http://nlcollege.es" target="_blank"> nlcollege.es </a>
            <a href="mailto:info@nlcollege.es" target="_blank">
              info@nlcollege.es
            </a>

            <div class="socials">
              <a
                href="https://www.instagram.com/nlcollege.spain/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                href="https://ne-np.facebook.com/nlcollege/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@nlcollege.spain"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-tiktok"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/nlcollege-spain/mycompany/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div
            class="col-md-4 d-flex justify-content-md-end justify-content-center mt-4 mt-md-0"
          >
            <p>© 2024</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import countries_en from "@/assets/json/countries-en.json";
import Checkbox from "@/components/Forms/Checkbox.vue";
import Radio from "@/components/Forms/Radio.vue";
import Select from "@/components/Forms/Select.vue";
import axios from "axios";

export default {
  components: {
    Checkbox,
    Radio,
    Select,
  },
  props: {},
  data: () => ({
    courses: [
      "Degree in Anthropology",
      "Degree in Technical Architecture",
      "Degree in German Studies",
      "Degree in Arabic and Islamic Studies",
      "Degree in French Studies",
      "Degree in Italian Studies",
      "Degree in Portuguese and Brazilian Studies",
      "Degree in Geography",
      "Degree in Geology",
      "Degree in Humanities",
      "Degree in Information and Documentation",
      "Degree in Agri-Food Engineering",
      "Degree in Civil Engineering",
      "Degree in Mining and Energy Technology Engineering",
      "Degree in Materials Engineering",
      "Degree in Electrical Engineering",
      "Degree in Industrial Electronic and Automatic Engineering",
      "Degree in Engineering in Geoinformation and Geomatics",
      "Degree in Geological Engineering",
      "Degree in Romance Languages, Literatures and Cultures",
      "Degree in Hebrew and Aramaic Studies",
      "Degree in SMEs",
      "I don't know yet",
    ],
    whyCards: [
      {
        title: "Boost your Spanish language skills",
        subtitle:
          "Achieve the minimum required A2 level for university studies.",
      },
      {
        title: "Academic excellence",
        subtitle:
          "Prepare for university-level coursework with expert guidance.",
      },
      {
        title: "Guaranteed access to University",
        subtitle: "Secure entry to 22 degree programs at USAL.",
      },
      {
        title: "Legal right to work",
        subtitle:
          "Enjoy peace of mind with a 1-year visa (category D) allowing part-time work (up to 30 h/week).",
      },
      {
        title: "Work experience",
        subtitle:
          "Gain valuable working experience, enhance your resume and aquire practical skills for your future career.",
      },
      {
        title: "Cultural immersion",
        subtitle: "Experience the rich culture and vibrant lifestyle of Spain.",
      },
    ],

    form: {
      name: null,
      email: null,
      phone: null,
      nationality: null,
      course: null,
      campus: null,
      message: null,
      terms: false,
    },
  }),
  computed: {
    nationalities() {
      return countries_en.map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  methods: {
    sendForm() {
      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-name": this.form.name,
            "your-email": this.form.email,
            "your-phone": this.form.phone,
            "your-nationality": this.form.nationality,
            "your-course": this.form.course,
            "your-campus": this.form.campus,
            "your-message": this.form.message,
            token: "kt0phw2302by8qt5119500846942344b1",
          },
        })
        .then(() => {
          this.$router.push({ name: "thanks" });
        });
    },
  },
};
</script>

<style></style>
